import React from 'react'
import PermanentDrawerLeft from './PermanentDrawerLeft'

function AdminHome() {
  return (
    <>
      <PermanentDrawerLeft />
    </>
  )
}
export default AdminHome