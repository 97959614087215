import * as React from 'react';
import { useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { userloggedIn } from '../Context/Context';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SendConsignSeparate({ setconsignmentDialogOpen, consignmentDialogOpen, handleClickOpenConsignment, handleCloseConsignment }) {
  const { userlogged, setuserLogged, userData, setuserData, url } = useContext(userloggedIn);
  const [sendLoading, setSendLoading] = React.useState(false);
  // console.log(val);
  const [to, setTo] = React.useState('');
  const [subject, setSubject] = React.useState(`Invitation to Fill Consignment Form`);
  const [carMake, setcarMake] = React.useState();
  const [carModel, setcarModel] = React.useState();
  const [body, setBody] = React.useState(`Following on from our recent discussion, we look forward to running a successful auction with you. In order for us to compile a detailed description for you to review and approve, we need you to tell us as much as possible about your vehicle. <br/> <br/>
The more information we have about its history and condition, the more reassurance we will provide to bidders - which is how we achieve the strongest prices on the platform. *Please fill out the consignment form here*, or click the button below. Please ensure you make note of any faults or flaws, as well as the positive selling points, and gather any relevant paperwork so that you can refer to it when completing the form.<br/><br/>
If you have any questions, please don't hesitate to get in touch.<br/><br/>
Many thanks,<br/>
Henry`);
  const [firstName, setfirstName] = React.useState();
  const [lastName, setlastName] = React.useState();
  const [notes, setnotes] = React.useState();
  const [senderName, setsenderName] = React.useState();

  const [open1, setopen1] = React.useState(false);
  // Error message
  const [open2, setopen2] = React.useState(false);
  const vertical = 'top';
  const horizontal = 'center';

  const submitform = async (event) => {
    event.preventDefault();
    console.log('Sending cons2 ...');
    // setLoading(true);
    setSendLoading(true);
    const res = await fetch(`${url}consignmentFormSeparated`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        to: to,
        subject: subject,
        body: body,
        carMake: carMake,
        carModel: carModel,
        firstName: firstName,
        lastName: lastName,
        notes: notes,
        senderName: senderName,
      }),
    });
    const data = await res.json();
    setSendLoading(false);
    if (data.msg === 'success') {
      console.log(handleCloseConsignment);
      setopen2(false);
      setopen1(true);
      setTimeout(() => {
        setopen2(false);
        setopen1(false);
      }, 3000);
    } else {
      setopen1(false)
      setopen2(true);
      setTimeout(() => {
        setopen1(false);
        setopen2(false);
      }, 3000);
    }
  }

  return (
    <>
      <Snackbar open={open1} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
        <Alert severity="success" sx={{ width: '100%' }}>
          Email Sent Successfully!
        </Alert>
      </Snackbar>
      <Snackbar open={open2} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
        <Alert severity="error" sx={{ width: '100%' }}>
          Unauthorized request!
        </Alert>
      </Snackbar>
      <Dialog
        open={consignmentDialogOpen}
        onClose={handleCloseConsignment}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: { width: "40%", height: "100%" } }}
        maxWidth="200px"
      >
        <div style={{ backgroundColor: "#eaeaea24" }} >
          <div style={{ padding: "1rem" }} >
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '96%' },
              }}
              noValidate
              autoComplete="off"
              onSubmit={submitform}
            >
              <div className="cons_dialog_header">
                <div className="flex">
                  <Button type='submit' style={{ margin: '0 8px', backgroundColor: 'green', width: "9rem" }} disabled={sendLoading} variant="contained" endIcon={!sendLoading && <SendIcon />}>
                    {sendLoading ? <div class="loader-5 center" ><span></span></div> : <>Send Mail</>}
                  </Button>
                  <Button onClick={() => { handleCloseConsignment() }} variant="contained" style={{ width: '2rem' }}>
                    <svg style={{ height: '1.3rem', fill: 'white' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                  </Button>
                </div>
              </div>
              <div>
                <div className='flex' >
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Receiver email"
                    type='email'
                    value={to}
                    onChange={(e) => { setTo(e.target.value) }}
                    required
                  />
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Sender name"
                    type='text'
                    value={senderName}
                    onChange={(e) => { setsenderName(e.target.value) }}
                    required
                  />
                </div>
                <div className='flex' >
                  <TextField
                    id="outlined-multiline-flexible"
                    type='text'
                    label="First name"
                    value={firstName}
                    required
                    onChange={(e) => { setfirstName(e.target.value) }}
                  />
                  <TextField
                    id="outlined-multiline-flexible"
                    type='text'
                    label="Last email"
                    value={lastName}
                    onChange={(e) => { setlastName(e.target.value) }}
                    required
                  />
                </div>
                <div className='flex' >
                  <TextField
                    id="outlined-multiline-flexible"
                    type='text'
                    label="Car make"
                    value={carMake}
                    onChange={(e) => { setcarMake(e.target.value) }}
                    required
                  />
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Car model"
                    value={carModel}
                    onChange={(e) => { setcarModel(e.target.value) }}
                    required
                  />
                </div>
                <TextField
                  id="outlined-textarea"
                  label="Subject"
                  placeholder="Enter subject"
                  multiline
                  defaultValue={subject}
                  onChange={(e) => { setSubject(e.target.value) }}
                  required
                />
                <TextField
                  id="outlined-multiline-static"
                  label="Body"
                  multiline
                  onChange={(e) => { setBody(e.target.value) }}
                  rows={16}
                  defaultValue={body}
                  required
                />
              </div>
            </Box>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default SendConsignSeparate
