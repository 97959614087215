import React, { useState } from 'react'
import './Admin.css'
import EnquiryBox from './EnquiryBox'
import Button from '@mui/material/Button';
import SendConsignSeparate from './SendConsignSeparate';
import SendEmailPopup from './SendEmailPopup';

function SellingEquiries({ allSellerQueries, Enquiries, setEnquiries }) {

    const [consignmentDialogOpen, setconsignmentDialogOpen] = React.useState(false);
    const handleClickOpenConsignment = () => {
        setconsignmentDialogOpen(true);
    };
    const handleCloseConsignment = () => {
        setconsignmentDialogOpen(false);
    };

    const [sendEmail,setsendEmail] = useState(false);
    const handleOpenSendEmail = ()=>{
        setsendEmail(true);
    }
    const handleCloseSendEmail = ()=>{
        setsendEmail(false);
    }

    React.useEffect(() => {
        allSellerQueries();
    }, [])
    return (
        <>
            <div className="eb_dialog_header" >
                <div className="eb_head_left"></div>
                <div className="eb_head_right flex">
                    <Button variant="outlined" onClick={handleOpenSendEmail} >
                        Send email
                    </Button>
                    <Button variant="outlined" onClick={handleClickOpenConsignment} >
                        Consignment
                    </Button>
                </div>
            </div>
            <div className='flex_wrap' >
                {
                    Enquiries.map((val, ind) => {
                        return (<>
                            <EnquiryBox val={val} allSellerQueries={allSellerQueries} ind={ind} />
                        </>)
                    })
                }
            </div>
            {consignmentDialogOpen && <SendConsignSeparate setconsignmentDialogOpen={setconsignmentDialogOpen}
                consignmentDialogOpen={consignmentDialogOpen} handleClickOpenConsignment={handleClickOpenConsignment}
                handleCloseConsignment={handleCloseConsignment}
            />}
            {sendEmail && <SendEmailPopup sendEmail={sendEmail} 
                setsendEmail={setsendEmail} handleOpenSendEmail={handleOpenSendEmail}
                handleCloseSendEmail={handleCloseSendEmail}
            />

            }
        </>
    )
}

export default SellingEquiries